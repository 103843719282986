import React from 'react';
import '../../LandingGrid.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const state = {
     margin: 30,
     lazyLoad:true,
     responsiveClass: true,
     nav: true,
     autoplay: false,
     smartSpeed: 1000,
     responsive: {
          0: {
               items: 2,
          },
          400: {
               items: 2,
          },
          600: {
               items: 2,
          },
          700: {
               items: 3,
          },
          1000: {
               items: 5,
          }
     },
};

const LandingGrid = () => {
     return (



          <section  >
               <h1 className="headerTitle" >BRANDS WE CARRY</h1>
               <div>
                    <div class='container-fluid' >
                         <div className="row title" style={{ marginBottom: "20px" }} >
                              <div class="col-sm-12 btn btn-secondary">

                                   Authorized dealer of all your favorite brands.

                              </div>
                         </div>
                    </div>
                    <div className="container-fluid" >
                         <OwlCarousel items={5} margin={8} autoplay={true} loop={true} lazyLoad={true} responsive={state.responsive} >
                              <div className="picDiv"><img className="img owl-lazy" data-src='/asc1.webp' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/aka1.webp' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/amt1.webp' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/arabig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/axial.webp' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/csebig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/dlmbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/dtxbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/dynbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/dubbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/ecx.png'    /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/eflbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/estes.png'  /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/exlbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/etkbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/evgbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/tfebig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/futaba.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/geabig.gif' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/hrcbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/hbzbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/hrabig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/hw.png'     /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/iwabig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/intbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/jco.png'    /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/kyosho.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/losbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/mipbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/mugbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/osmbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/pasbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/prbbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/probig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/prmbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/rjsbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/rmxbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/rpmbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/rrpbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/rc4big.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/spmbig.png' /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/sav1.png'   /></div>
                              <div className="picDiv"><img className="img owl-lazy" data-src='/spaz.png'   /></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tacbig.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tam.png'   /></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tkrbig.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tekbig.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tlrbig.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/tkpbig.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/traxxas.png'/></div>
                              <div className="picDiv" ><img className="img owl-lazy" data-src='/vpbig.png'/></div>
                         </OwlCarousel>

                    </div>

               </div>
          </section>

     )
}

export default LandingGrid;


// https://imageresizer.com/