import React from 'react'
import TopNav from '../Nav/TopNav';
import MainNav from '../Nav/MainNav';
import FooterBottom from '../FooterBottom/FooterBottom';
import BottomFooter from '../Home/BottomFooter';
import PrivacyContent from './PrivacyContent';

function Privacy() {
    return (
        <div>
            <TopNav />
            <MainNav />
            <PrivacyContent />
            <FooterBottom />
            <BottomFooter />

        </div>
    )
}

export default Privacy