import React from 'react'
import './Maps.css';

function Maps() {
  return (
    <div className="mapContainer" id="location">
      <hr />
        <h1 className="headerTitle">OUR LOCATION</h1>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.1749395978013!2d-120.47021958550245!3d34.7007672907239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ec19082fdd56ff%3A0x7a572cae1384f44f!2s3734%20Constellation%20Rd%2C%20Lompoc%2C%20CA%2093436!5e0!3m2!1sen!2sus!4v1629020421166!5m2!1sen!2sus" 
    width="100%" height="495" style={{border:0}} allowfullscreen="" loading="lazy"></iframe></div>
  )
}

export default Maps