import React from 'react'
import './Events.css';

function EventBackground() {
    return (
        <div className='eventContainer' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', objectFit: 'contain' }} >
            {/* <img src='/backgroundrace.jpg' style={{
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
                position: 'relative', zIndex: '-3'
            }}>     
            </img> */}
            <div className="image">  <img src='/backgroundrace.jpg' style={{
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
                position: 'relative', zIndex: '-1'
            }}>     
            </img></div>
            <div class="centered">Coming soon!</div>
            


        </div>

    )
}

export default EventBackground