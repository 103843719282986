import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopNav.css';

const TopNav = () => {
  return (

  

    <div className="topNavContainer">
        <div className="left">
        <ul className="contentLeft">
          <li className="leftIcon"><a  href="tel:805-741-7249" style={{ color: 'white', fontSize:'14px'  }}><i class="bi bi-phone"></i> 805-741-7249</a></li>
          <li className="leftIcon"><a  href="https://www.google.com/maps/place/3734+Constellation+Rd,+Lompoc,+CA+93436/@34.7006336,-120.468382,299m/data=!3m1!1e3!4m5!3m4!1s0x80ec19082fdd56ff:0x7a572cae1384f44f!8m2!3d34.7007629!4d-120.4680309" style={{ color: 'white', fontSize:'14px' }}><i class="bi bi-geo-alt-fill"></i> 3734 Constellation Road STE B, Lompoc, CA 93436</a></li>
        </ul>
        </div>

        <div className="right">
        <ul className="contentRight">
          <li><a href="https://www.facebook.com/Fast-Lap-RC-Hobbies-246414645531092" target="_blank"><i class="bi bi-facebook" style={{ color: 'white', fontSize: '22px' }}></i></a></li>
          <li><a href="https://www.instagram.com/fastlaprchobbies/?hl=en" target="_blank"><i class="bi bi-instagram" style={{ color: 'white', fontSize: '22px' }}></i></a></li>
          <li><a href="https://twitter.com/fastlaprc" target="_blank"><i class="bi bi-twitter" style={{ color: 'white', fontSize: '22px' }}></i></a></li>

        </ul>
        </div>

    </div>

  )
}

export default TopNav;