import React from 'react'
import './Privacy.css';

function PrivacyContent() {
    return (
        <section className="contentDiv">
            <section className="privacyDiv">

                <h2 className="privacyTitle"> Fast Lap RC Hobbies Website Privacy Policy</h2>
                <p>Fast Lap RC Hobbies ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                    Visit our website at http://www.fastlaprchobbies.com, or any website of ours that links to this
                    privacy notice. In this Privacy Policy we explain how we collect, use and disclose the information we collect about users of our Sites and Services. Your use of the Sites and Services is subject to this Privacy Policy and the Fast Lap RC Hobbies Terms of Use, including applicable terms of limitations on liability and the resolution of disputes. Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns,
                    please contact us at 805-741-7249.
                </p>

                <p>
                    WHAT INFORMATION DO WE COLLECT?
                    <br></br>
                    DO WE SHARE YOUR INFORMATION?
                    <br></br>
                    COOKIES AND TRACKING
                    <br></br>
                    CHILDREN’S INFORMATION
                    <br></br>
                    THIRD PARTY PLUGINS AND SOCIAL NETWORKING FEATURES
                    <br></br>
                    CONTACT US
                    <br></br>
                    UPDATES AND CHANGES TO PRIVACY POLICY
                    <br></br>

                </p>

                <p>
                    <strong>
                        WHAT INFORMATION DO WE COLLECT?
                    </strong>
                    <br></br>
                    In short: We collect personal information that you provide to us.
                    <br></br>

                    We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services,
                    when you participate in activities on the Services, or otherwise when you contact us.


                    <br></br>
                    Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                    name, email address, or phone number.

                </p>

                <p>
                    <strong>
                        DO WE SHARE YOUR INFORMATION?
                    </strong>
                    <br></br>
                    Any information that you provide is NOT transfered to any third party services in any way.
                </p>

                <p>
                    <strong>
                        COOKIES AND TRACKING
                    </strong>
                    <br></br>
                    "Cookies" are small data files stored on your own computer's hard drive. We (and our third party providers) use cookies to facilitate the operation of our Site (e.g., as part of the account login and checkout features),
                    to personalize the content we display to you, and for analytics, marketing, and advertising purposes. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. If you choose to reject cookies, certain functions and applications on our Sites may not work properly.
                </p>
                <p>
                    Pixel tags (a.k.a. web beacons, web bugs or clear GIFs) are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, though, clear GIFs are embedded invisibly on web pages, not stored on your hard drive. We might use clear GIFs to track the activities of users of our Sites, help us manage content, and compile statistics about usage. We and our third party service providers also might use clear GIFs in HTML e-mails to our customers, to help us track e-mail response rates, identify when our e-mails are viewed, and track whether our e-mails are forwarded.

                </p>
                <p>

                    Third Party Analytics. We use automated devices and applications, such as
                    <a className="link" href='https://policies.google.com/technologies/partner-sites'> Google Analytics </a>
                    including to determine the popularity of certain content, deliver advertising and content targeted to your interests on our websites and other websites and better understand your online or offline activity. We use these tools to help us improve our Sites, performance and user experiences. These entities may use cookies and other tracking technologies to perform their services. We do not share your personal information with these third parties.

                </p>


                <p>
                    <strong>
                        CHILDREN’S INFORMATION

                    </strong>
                    <br></br>
                    Our Sites do not target children, and we do not knowingly collect any personal information from children under the age of thirteen (13).
                </p>

                <p>
                    <strong>
                        THIRD PARTY PLUGINS AND SOCIAL NETWORKING FEATURES
                    </strong>

                    <br></br>
                    At times, we may incorporate some third-party social networking features on the Sites or utilize third-party provided platforms to publish or manage the Sites or portions thereof. For example, our Sites may include social media features and widgets, such as the Facebook Like button and the "Share this" button, as well as other interactive mini-programs that run on our Sites and enable certain features to work (e.g., blogs and comments). These features may collect your IP address, which page you are visiting on our Sites, and may set a cookie to enable the feature to function properly and for other purposes. These features are displayed on our Sites, but are hosted by third parties,
                    and are subject to their own privacy policies, not this one.
                </p>

                <p>
                    <strong>
                        CONTACT US
                    </strong>
                    <br></br>
                    If you have a question or concern regarding this Privacy Policy or our information practices,
                    you may write to Fast Lap RC Hobbies at the following address:

                </p>

                <p>
                    Fast Lap RC Hobbies
                    <br></br>
                    3734 Constellation Road Suite B
                    <br></br>
                    Lompoc, CA 93436
                    <br></br>
                </p>

                <p>
                    <strong>
                        UPDATES AND CHANGES TO PRIVACY POLICY
                    </strong>
                    <br></br>
                    We change, update, or modify this Privacy Policy from time to time. We will post the revised version on each of our Sites, so we suggest that you check here periodically for the most up-to-date version of our Privacy Policy. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our Sites,
                    or contacting you at your email address of record with us.
                </p>

            </section>
        </section>
    )
}

export default PrivacyContent