import React from 'react';
import './HomeBackground.css';

const HomeBackground = () => {
    return (

        <div className='hero-container' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', objectFit: 'contain' }} >
            <video className="vid" src='/Videos/clip.mp4' playsInline={true} autoPlay={true} muted={true} ></video>

            {/* <h1 class='sticky ' id='baseText'  /Videos/flvid1.mp4 style={{float:'center', textAlign:'center', marginTop: '20px'}}><span style={{color: '#f2C75C'}}></span></h1> */}
        </div>


    )
}

export default HomeBackground;

// style={{
//     objectFit: 'cover',
//     width: '100%',
//     height: '610px',
//     position: 'relative', zIndex:'-3'}}