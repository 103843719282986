import React from 'react'

const BottomFooter = () => {
    return (
        <div>
          <h6 style={{
            position: 'relative',
            bottom: '0',
            background: '#2e3033',
            padding: '1.325em', //.625
            margin: '0',
            width: '100%',
            height: '100%', //2.5
            display: 'flex',
            justifyContent: 'center',
            color: '#FFFFFF' }}>Copyright &copy; 2022 Fast Lap RC Hobbies</h6>
        </div>

    );
}

export default BottomFooter;