import React from 'react'
import './AboutUs.css';


function AboutUs() {
    return (
        <div className="aboutContainer">
            <div className="contentAbout">
                <h2 className="aboutTitle">About Us</h2>
                <br></br>
                <p>
                    Fast Lap RC Hobbies is a hobby shop located in Vandenberg Village,
                    proudly serving the central coast since 2014. We are a full service shop performing everything from simple repairs to doing customs builds and
                    offer competitive prices. We have over 35+ years of combined experience in the hobby, previously attending various rc racing events around
                    California and Nevada. We have the skills and knowledge to provide the best customer service and help answer any questions
                    whether it is your first time buying an rc or looking for suggestions, we are here to help.
                    Visit us and see what we are all about.
                </p>
                <p>
                    Contact us at 805-741-7249 or visit us on Facebook!
                </p>

            </div>
        </div>
    )
}

export default AboutUs