import React from 'react'
import TopNav from '../Nav/TopNav';
import MainNav from '../Nav/MainNav';
import FooterBottom from '../FooterBottom/FooterBottom';
import BottomFooter from '../Home/BottomFooter';

import AboutUs from './AboutUs';

function About() {
    return (
        <div><TopNav />
            <MainNav />
            <AboutUs />
            <FooterBottom />
            <BottomFooter />
        </div>
    )
}

export default About