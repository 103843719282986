import React from 'react'
import BottomFooter from '../Home/BottomFooter'
import FooterBottom from '../FooterBottom/FooterBottom'
import MainNav from '../Nav/MainNav'
import TopNav from '../Nav/TopNav'
import EventBackground from './EventBackground'

function Events() {
    return (
        <div>

            <TopNav />            
            <MainNav />
            <EventBackground />
            <FooterBottom />
            <BottomFooter />

        </div>
    )
}

export default Events