import React from 'react'
import './FooterBottom.css';

function FooterBottom() {
	return (
		<footer className="footer">
			<div className="container">
				<div className="mrow">
					<div class="footer-col" id="hours">
						<h4>Store Hours</h4>
						<ui>
							<li> Sun: Closed</li>
							<li> Mon: Closed</li>
							<li> Tues: 11 am - 6 pm</li>
							<li> Wed: 11 am - 6 pm</li>
							<li> Thurs: 11 am - 6 pm</li>
							<li> Fri: 11 am - 6 pm</li>
							<li> Sat: 11 am - 6 pm</li>
						</ui>
					</div>
					<div class="footer-col">
						<h4>Fast Lap RC Hobbies</h4>
						<ui>
							<li><a href="https://www.google.com/maps/place/3734+Constellation+Rd,+Lompoc,+CA+93436/@34.7006336,-120.468382,299m/data=!3m1!1e3!4m5!3m4!1s0x80ec19082fdd56ff:0x7a572cae1384f44f!8m2!3d34.7007629!4d-120.4680309"><i class="bi bi-geo-alt-fill"></i> 3734 Constellation Road Suite B, Lompoc, CA 93436</a></li>
							{/* <li>Lompoc, CA 93436</li> */}
							<li><a href="tel:805-741-7249" ><i class="bi bi-phone"></i> 805-741-7249</a></li>

						</ui>
					</div>
					<div class="footer-col">
						<h4>Information</h4>
						<ui>
							<li><a href='/about'>About Us</a></li>
							<li><a href='/events'>Events</a></li>
							{/* <li><a href='/terms'>Terms & Conditions</a></li> */}
							<li><a href='/privacy'>Privacy Policy</a></li>
						</ui>
					</div>
					<div class="footer-col">
						<h4>follow us</h4>
						<div class="social-links">
							<a href="https://www.facebook.com/Fast-Lap-RC-Hobbies-246414645531092" target="_blank"><i class="bi bi-facebook"
							></i></a>
							<a href="https://www.instagram.com/fastlaphobbies/?hl=en" target="_blank">
								<i class="bi bi-instagram" ></i></a>
								<a href="https://twitter.com/fastlaprc" target="_blank">
								<i class="bi bi-twitter" ></i></a>
						</div>
					</div>
				</div>

			</div>
		</footer>

	)
}

export default FooterBottom