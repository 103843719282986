import React from 'react'
import TopNav from '../Nav/TopNav';
import MainNav from '../Nav/MainNav';
import HomeBackground from '../HomeBackground/HomeBackground';
import LandingGrid from './LandingGrid';
import BottomFooter from './BottomFooter';
import Maps from '../Map/Maps';
import FooterBottom from '../FooterBottom/FooterBottom';
// import './Home.css';
import Release from '../Release/Release';



const Home = () => {
   return (

      <div class='pageContainer'>

         <TopNav />
         <MainNav />
         <HomeBackground />
         <Release />
         <LandingGrid />
         <Maps />
         <FooterBottom />
         <BottomFooter />
      </div>


   );
}
export default Home;