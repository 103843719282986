import React from 'react'
import './Release.css';
import Carousel from 'react-bootstrap/Carousel';

function Release() {
    return (
        <div className="ReleaseContainer">
            <h1 className="headerTitle">FEATURED PRODUCTS</h1>
            <Carousel fade className="ReleaseImages">
            <Carousel.Item id="Carousel-item">
                <img 
                    className="d-block w-100"
                    src="ax24.webp"
                    alt="First slide"
                />
               
            </Carousel.Item>
            {/* <Carousel.Item>
                <img 
                    className="d-block w-100"
                    src="trm.webp"
                    alt="Second slide"
                />

               
            </Carousel.Item> */}
            <Carousel.Item>
                <img 
                    className="d-block w-100"
                    src="capra.webp"
                    alt="Third slide"
                />

                
            </Carousel.Item>
            <Carousel.Item>
                <img 
                    className="d-block w-100"
                    src="cc10.webp"
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
        </div>
        
    );
}

export default Release;


