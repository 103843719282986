import './App.css';
import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from "./Components/Home/Home";
import Privacy from "./Components/Privacy/Privacy";
import Terms from "./Components/Terms/Terms";
import About from "./Components/About/About";
import Events from "./Components/Events/Events";
import Contact from "./Components/Contact/Contact";



function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path='/' component={Home} />
        <Route exact path='/privacy' component={Privacy} />
        {/* <Route exact path='/terms' component={Terms} /> */}
        <Route exact path='/about' component={About} />
        <Route exact path='/events' component={Events} />
        {/* <Route exact path='/contact' component={Contact} /> */}


        







      </div>
    </Router>
  );
}

export default App;
