import React from 'react';
import './Nav.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MainNav = () => {
return (

    <div className="MainNav">
   
<Navbar bg="light" expand="lg" className="pt-3 pb-3 ps-3">
      <Container fluid>
        <Navbar.Brand href="/"><img id="logo"
              alt=""
              src='/logof1.png'
              width= "350px"
              height= "35px"
              className="d-inline-block align-top"
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll ">
          
          <Nav
            className="my-2 my-lg-0  ms-auto pe-5"
            style={{ maxHeight: '100px' }}
            navbarScroll>
            <Nav.Link className="nav-links" href="/"  style={{color: "#383E47", fontSize: "15px", fontWeight: "600"}}>HOME</Nav.Link>
            <Nav.Link className="nav-links" href="/about" style={{color: "#383E47", fontSize: "15px", fontWeight: "600"}}>ABOUT US</Nav.Link>
            <Nav.Link className="nav-links" href="/events" style={{color: "#383E47", fontSize: "16px", fontWeight: "600"}}>EVENTS</Nav.Link>
            {/* <Nav.Link className="nav-links" href="/contact" style={{color: "#383E47", fontSize: "16px", fontWeight: "600"}}>CONTACT US</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    </div>


)
}

export default MainNav;